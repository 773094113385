import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import platform from "platform"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from "gatsby"
import SEO from "../components/seo"

const platformNames = {
  'OS X': 'MacOs',
  'Windows': 'Windows',
  "Ubuntu": 'Linux',
  "Debian": 'Linux',
  "Fedora": 'Linux',
  "Red Hat": 'Linux',
  "SuSE": 'Linux'
}

const availablePlatforms = [
  {name: 'Windows', link: 'https://github.com/invizi/invizi/releases/download/v1.0.0/Invizi.Setup.1.0.0.exe'},
  {name: 'MacOs', link: 'https://github.com/invizi/invizi/releases/download/v1.0.0/Invizi-1.0.0.dmg'},
  {name: 'Linux', links:
   [
     {name: 'rpm', link: 'https://github.com/invizi/invizi/releases/download/v1.0.0/Invizi-1.0.0.x86_64.rpm'},
     {name: 'deb', link: 'https://github.com/invizi/invizi/releases/download/v1.0.0/Invizi_1.0.0_amd64.deb'},
     {name: 'snap', link: 'https://github.com/invizi/invizi/releases/download/v1.0.0/Invizi_1.0.0_amd64.snap'},
     {name: 'AppImage', link: 'https://github.com/invizi/invizi/releases/download/v1.0.0/Invizi-1.0.0.AppImage'}
   ]}
]

function DownloadButton(props) {
  const plat = props.platform

  if (plat.name === 'Linux') {
    return plat.links.map(el => {
      return (
        <a className="main-cta button is-large center" href={el.link} style={{'margin-left': '1rem', 'margin-bottom': '1rem'}}>
          Download {el.name}
        </a>
      )
    })
  }
  return <a className="main-cta button is-large center" href={plat.link}>
             Download for {plat.name}
           </a>
}

class Download extends React.Component {
  state = {
    currentPlatform: availablePlatforms[0],
    otherAvailablePlatforms: availablePlatforms.filter(obj => obj.name !== availablePlatforms[0].name)
  }

  componentDidMount () {
    const currentPlatform = availablePlatforms.find(obj => obj.name === platformNames[platform.os.family]) || availablePlatforms[0]
    const otherAvailablePlatforms = availablePlatforms.filter(obj => obj.name !== currentPlatform.name)

    this.setState({
      currentPlatform: currentPlatform,
      otherAvailablePlatforms: otherAvailablePlatforms
    })
  }


  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title='Download'
          description='Download page. Invizi is available for MacOs, Windows and Linux'
        />
        <section className="section-home-intro section-lead-intro">
          <div className="container row">
            <div className="col lead-section">
              <h1 className="lead">Download Invizi</h1>
              <p className="intro">
                Private, secure and open source, Invizi protects you.
              </p>
              <div className="text-center">
                <DownloadButton platform={this.state.currentPlatform}/>

                <p style={{'marginTop': '2rem'}}>
                  Downloads of this version of Invizi are also available for {this.state.otherAvailablePlatforms.map(el => {
                    if (el.links) {
                      return el.links.map(link => {
                        return (<a href={link.link}>{el.name} {link.name}, </a>)
                      })
                    } else {
                      return (<a href={el.link}>{el.name}, </a>)
                    }
                  })}
                </p>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

// document.getElementsByTagName('video')[0].onended = function () {
//   this.load();
//   this.play();
// };

export default Download

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
